:root {
  --darkGoldColor: #645123;
  --mediumGoldColor: #98793d;
  --lightGoldColor: #f4e2ac;
}

.goldStyle {
  background: var(--lightGoldColor);
  border-color: var(--mediumGoldColor);
  color: var(--darkGoldColor);
}

.borderStyle {
  border-radius: 8px;
  border: 1px solid;
}
